import { drawer } from "../scripts/main/drawer";
import "../scripts/main/flip-clock";
import "../scripts/main/notifications-manager";
import "../scripts/main/modals";
import posthog from 'posthog-js'

posthog.init('phc_7dOPbpzl46ocyZF9onLfUS30A77zYObDMAFI1b0IRME',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always' // 'identified_only' or 'always' to create profiles for anonymous users as well
    }
)

if (posthog.getFeatureFlag('blog-cta') === 'blog') {
  const blogCTA = document.querySelector("#shopify-section-cle-header > nav > div.flex-1.hidden.lg\\:flex > div:nth-child(4) > a") as HTMLAnchorElement;// Do something differently for this user
  blogCTA.innerText = "Blog";
} else {
    // It's a good idea to let control variant always be the default behaviour,
    // so if something goes wrong with flag evaluation, you don't break your app.
}

// TODO: Move as input validation in the product calculator code.
document.addEventListener("DOMContentLoaded", () => {
  initializeDropdowns();
});

window.setLengthLimitForInput = (input: HTMLInputElement, maxLength = 4) => {
  const maxLengthAttr = input.getAttribute('maxlength');
  maxLength = maxLengthAttr ? parseInt(maxLengthAttr) : maxLength;
  if (input.value.length > maxLength) {
    input.value = input.value.slice(0, maxLength);
  }
}

window.cletile = {
  module: {
    drawer
  }
}

function initializeDropdowns() {
  document.addEventListener('click', (e) => {
    const target = e.target as HTMLElement;
    if (!target?.closest('.js-has-dropdown')) {
      closeAllNav();
    }
  });

  const dropdownHoverElements = document.querySelectorAll(".js-has-dropdown") as NodeListOf<HTMLElement>;
  dropdownHoverElements.forEach(element => {
    element.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      const parent = target?.closest('.js-has-dropdown') as HTMLElement;
      e.stopImmediatePropagation();
      if (parent?.classList.contains('js-dropdown-open') && !target?.classList.contains('js-has-dropdown')) {
        closeDropdown(parent)
      } else {
        if (!parent?.parentElement) return;
        closeSiblings(parent?.parentElement);
        openDropdown(parent);
      }
    }, true);
  });
}

function closeAllNav () {
  const dropdowns = document.querySelectorAll('.js-dropdown-open') as NodeListOf<HTMLElement>;
  dropdowns.forEach(dropdown => {
    closeDropdown(dropdown);
  });
}

function openDropdown (parent:HTMLElement) {
  const childNav = parent.querySelector('div');
  if (!childNav) return;
  parent.classList.add('js-dropdown-open');
  childNav.classList.add('flex');
  childNav.classList.remove('hidden');
}

function closeDropdown (parent:HTMLElement) {
  const childNav = parent.querySelector('div');
  if (!childNav) return;
  parent.classList.remove('js-dropdown-open');
  childNav.classList.remove('flex');
  childNav.classList.add('hidden');
}

function closeSiblings (parent:HTMLElement) {
  const siblings = parent.querySelectorAll('.js-dropdown-open') as NodeListOf<HTMLElement>;
  siblings.forEach(sibling => {
    closeDropdown(sibling);
  });
}