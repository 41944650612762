
  var closeLoginButton = document.querySelector('i.modal-close'),
      openLoginButton = document.querySelector('span[data-testid="loginOpen"]'),
      overlay = document.querySelector('.overlay.fixed'),
      loginForm = document.querySelector('#modal-login-form'),
      accountForm = document.querySelector('#modal-create-account-form'),
      passwordForm = document.querySelector('#modal-forgot-password-form');

  function openLoginModal(){
    document.querySelector('div.modal[data-v-24292b0c]').classList.add('fade-enter-active');
    document.querySelector('div.modal[data-v-24292b0c]').classList.remove('fade-leave-active');
    document.querySelector('.overlay.fixed').style.display = 'block';
    document.querySelector('#modal-login-form').style.display = 'block';
    document.querySelector('#closeLogin').style.display = 'block';
    passwordForm.style.display = 'none';
    accountForm.style.display = 'none';
  }

  function openAccountModal() {
    document.querySelector('div.modal[data-v-24292b0c]').classList.add('fade-enter-active');
    document.querySelector('div.modal[data-v-24292b0c]').classList.remove('fade-leave-active');
    document.querySelector('.overlay.fixed').style.display = 'block';
    document.querySelector('#closeLogin').style.display = 'block';
  }

  function closeLoginModal(){
    document.querySelector('div.modal[data-v-24292b0c]').classList.add('fade-leave-active');
    document.querySelector('div.modal[data-v-24292b0c]').classList.remove('fade-enter-active');
    document.querySelector('.overlay.fixed').style.display = 'none';
    document.querySelector('#closeLogin').style.display = 'none';
  }
  
  function showPasswordForm(){
    loginForm.style.display = 'none';
    passwordForm.style.display = 'block';
    accountForm.style.display = 'none';
  }
  function showLoginForm(){
    openAccountModal();
    loginForm.style.display = 'block';
    passwordForm.style.display = 'none';
    accountForm.style.display = 'none';
  }
  function showAccountForm() {
    openAccountModal();
    loginForm.style.display = 'none';
    passwordForm.style.display = 'none';
    accountForm.style.display = 'block';
  }

  (function(){
  // close menu
  closeLoginButton.addEventListener('click', closeLoginModal );
  overlay.addEventListener('click', closeLoginModal );
  document.getElementById('closeLogin').addEventListener('click', closeLoginModal );

  // open menu
  if( openLoginButton ) {
    openLoginButton.addEventListener('click',showLoginForm);
  }

  //show password form
  document.querySelector('#forgot-password-link').addEventListener('click', function(e){
    e.preventDefault();
    showPasswordForm();
  });
  //show create account form
  document.querySelector('#create-account-link').addEventListener('click', function(e){
    e.preventDefault();
    showAccountForm();
  });
  //show login form
  document.querySelectorAll('.login-link').forEach((loginLink) => {
    loginLink.addEventListener('click', function(e){
      e.preventDefault();
      showLoginForm();
    });
  });

  const params = new URLSearchParams(window.location.search);
  if (params.get('login') === 'failed_login') {
    showLoginForm();
  }
  if (params.get('login') === 'password_reset') {
    showLoginForm();
  }
  if (params.get('login') === 'already_exists') {
    showAccountForm();
  }
  if (params.get('route') === 'register') {
    showAccountForm();
  }

})();