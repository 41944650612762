/**
 * @class drawer
 * @param {string} elementPrefix - The prefix of the drawer element
 * @param {string} position - The position of the drawer element
 * @description
 * This class is used to create a drawer element.
 * It exposes a drawer class on the cletile object in the window element
 * 
 *  It is used in the following way:
 *  document.addEventListener('DOMContentLoaded', () => {
 *    new window.cletile.drawer('filter-drawer', 'right')
 *  })
 */
export class drawer {
  prefix: string;
  position: string;

  constructor(elementPrefix: string, position: string = 'left') {
    this.prefix = elementPrefix
    this.position = position
    // Create Listeners
    document.getElementById(`js-${this.prefix}__open`)?.addEventListener('click', this.openMenu);
    document.getElementById(`js-${this.prefix}__close`)?.addEventListener('click', this.closeMenu);
  };

  openMenu = () => {
    // Handle overlay
    this.handleOverlay('activate');

    const positionClass = this.position === 'left' ? '-translate-x-full' : 'translate-x-full'
    document.getElementById(`js-${this.prefix}__drawer`)?.classList.remove(positionClass);
  };

  closeMenu = () => {
    // Handle overlay
    this.handleOverlay('deactivate');

    const positionClass = this.position === 'left' ? '-translate-x-full' : 'translate-x-full'
    document.getElementById(`js-${this.prefix}__drawer`)?.classList.add(positionClass);
  };
  
  handleOverlay = (action:string) => {
    const overlay = document.querySelector('.overlay.fixed') as HTMLElement;
    // If overlay is not present, return
    if (!overlay) return;

    if (action === 'activate') {
      overlay.style.display = 'block';
      document.documentElement.classList.add('no-scroll');
      overlay.addEventListener('click', this.closeMenu);
    }
    else if (action === 'deactivate') {
      overlay.style.display = 'none';
      document.documentElement.classList.remove('no-scroll');
      overlay.removeEventListener('click', this.closeMenu);
    }
  };
}
